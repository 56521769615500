import { makeGetStaticPaths, makeGetStaticProps } from '@quno/quno-gen';
import localesConfig from '@quno/patient-journey/src/config/locales.json';
import { renderBlock } from '@quno/patient-journey/src/contentful/patient-journey/renderBlocks';
import { contentfulConfig } from '@quno/patient-journey/src/config/contentful';
import { getPageById } from '@quno/patient-journey/src/contentful/patient-journey/queries/getPageById';
import { blockQueryMapper } from '@quno/patient-journey/src/contentful/patient-journey/blockQueryMapper';
import { patientJourneyApolloClient } from '@quno/patient-journey/src/contentful/patient-journey/apolloClient';

export const getStaticProps = makeGetStaticProps(
  patientJourneyApolloClient,
  localesConfig,
  getPageById,
  blockQueryMapper,
);

export const getStaticPaths = makeGetStaticPaths(
  contentfulConfig,
  localesConfig,
);

export default renderBlock;
